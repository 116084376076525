import React, { useState } from 'react';
import './AdminLogin.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../utils/API_URL';

const AdminLogin = () => {

    const [loading, setLoading] = useState(false); // State to manage loading status
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Validation schema for Formik using Yup
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    // Handle form submission
    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            // Example API call to login
            const response = await axios.post(`${API_URL}/admin/login`, values);

            // Handle success response (e.g., save token, navigate)
            localStorage.setItem('token', response.data.token);
            navigate('/musttech-solution/admin-panel');

        } catch (err) {
            setError('Invalid email or password');
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="login-container">
            <div className="login-form">
                <h2>Admin Panel Login</h2>
                {error && <p className="error-message">{error}</p>}

                <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Field type="email" name="email" id="email" />
                            <ErrorMessage name="email" component="div" className="error-message" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <Field type="password" name="password" id="password" />
                            <ErrorMessage name="password" component="div" className="error-message" />
                        </div>
                        <button type="submit" className="login-button" disabled={loading}>
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default AdminLogin;
