import React, { useState } from 'react'
import AdminLayout from '../../component/Admin-Layout/AdminLayout'
import "./JobForm.css";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { API_URL } from '../../utils/API_URL';


export default function JobEditForm() {

    const [loading, setLoading] = useState(false); // State to manage loading status

    const initialValues = {
        title: '',
        description: '',
        responsibilities: '',
        requirements: '',
        experience: '',
    };

    const validationSchema = Yup.object({
        title: Yup.string().required('Job title is required'),
        description: Yup.string().required('Job description is required'),
        responsibilities: Yup.string().required('Job responsibilities is required'),
        requirements: Yup.string().required('Job requirements is required'),
        experience: Yup.string().required('Experience is required'),
    });



    const onSubmit = async (values, { resetForm }) => {
        try {

        // Retrieve the token from localStorage (or wherever you stored it)
        const token = localStorage.getItem('token');

        // Configure the headers to include the Authorization token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`, // Add Bearer token in Authorization header
            },
        };


            setLoading(true); // Set loading to true when the request starts

            const response = await axios.post(`${API_URL}/job/post`, values, config);
            setLoading(false); // Set loading to true when the request starts

            // Show a success toast notification
            toast.success(response.data.message);

            // Clear form fields
            resetForm();
        } catch (error) {
            toast.error('There was an error submitting your form.');
            setLoading(false); // Set loading to true when the request starts

        }
    };

    return (
        <AdminLayout>

            <div className="job-form">
                <h3> Create Job </h3>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    <Form className='job-form'>

                        <label className='label'>Title</label>
                        <Field type="text" id="firstName" name="title" />
                        <ErrorMessage name="title" component="div" className='job-error' />

                        <label className='label'>Description</label>
                        <Field as="textarea" type="text" id="firstName" name="description" />
                        <ErrorMessage name="description" component="div" className='job-error' />


                        <label className='label'>Responsibilities</label>
                        <Field as="textarea" type="text" id="firstName" name="responsibilities" />
                        <ErrorMessage name="responsibilities" component="div" className='job-error' />


                        <label className='label'>Requirements</label>
                        <Field as="textarea" type="text" id="firstName" name="requirements" />
                        <ErrorMessage name="requirements" component="div" className='job-error' />

                        <label className='label'>Experience</label>
                        <Field type="text" id="firstName" name="experience" />
                        <ErrorMessage name="experience" component="div" className='job-error' />

                        <button
                            type="submit"
                            className="submit-button"
                            disabled={loading} // Disable button while loading
                        >
                            {loading ? 'Submiting...' : 'Submit'}
                        </button>


                    </Form>

                </Formik>

            </div>


        </AdminLayout>

    )
}
