import React from 'react'

import { Link, useLocation } from 'react-router-dom';

export default function Aside({ isSidebarOpen }) {

  const androidIcon = `${process.env.PUBLIC_URL}/assest/icon/logo.svg`;

  const location = useLocation();

  const asideItems = [
    { name: 'Dashoboard', path: '/musttech-solution/admin-panel/' },
    { name: 'Job', path: '/musttech-solution/admin-panel/job' },
    { name: 'Contact', path: '/musttech-solution/admin-panel/contact' },
    { name: 'Logout', path: '/musttech-solution/admin-panel/logout' },
  ];


  return (
    <>

      <aside className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="sidebar-title">
          <img src={androidIcon} alt="logo" />

        </div>
        <nav className="sidebar-nav">
          <ul>

            {asideItems.map((item, index) => (
              <>

                <li>
                  <Link
                    to={item.path}
                    className={location.pathname === item.path ? 'active' : ''}
                  >
                    {item.name}
                  </Link>
                </li>

              </>
            )
            )}




          </ul>
        </nav>
      </aside>

    </>
  )
}
