import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ContactList.css";
import AdminLayout from "../../component/Admin-Layout/AdminLayout";
import { API_URL } from "../../utils/API_URL";
import { useNavigate } from "react-router-dom";

const ContactList = () => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const navigate = useNavigate();

    // Fetch contacts from backend

    const fetchContacts = async () => {
        try {
            // Retrieve the token from localStorage (or wherever you stored it)
            const token = localStorage.getItem('token');

            // Configure the headers to include the Authorization token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`, // Add Bearer token in Authorization header
                },
            };
            setLoading(true);
            const response = await axios.get(`${API_URL}/contact/get`,config);
            setContacts(response.data);
            setLoading(false);
        } catch (err) {
            setError("Failed to fetch contacts");
            setLoading(false);
        }
    };

    useEffect(() => {


        fetchContacts();
    }, []);

    const handleDelete = async (id) => {
        try {
            setLoading(true);
            // Retrieve the token from localStorage (or wherever you stored it)
            const token = localStorage.getItem('token');

            // Configure the headers to include the Authorization token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`, // Add Bearer token in Authorization header
                },
            };
            
            await axios.delete(`${API_URL}/contact/delete/${id}`,config);
            setContacts((prevContacts) => prevContacts.filter(contact => contact._id !== id));
        } catch (err) {
            setError("Failed to delete contact");
        } finally {
            setLoading(false);
        }
    };

    const handleViewDetails = async (id) => {
        navigate(`/musttech-solution/admin-panel/contact-view/${id}`)
    };

    return (

        <AdminLayout>
            {

                loading && "loading contacts...."
            }

            <div className="contact-list">
                <h2>Contact List</h2>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Actions</th> {/* New column for buttons */}
                        </tr>
                    </thead>
                    <tbody>
                        {contacts.map((contact, i) => (
                            <tr key={contact._id}>
                                <td>{i + 1}</td>
                                <td>{`${contact.firstName}`}</td>
                                <td>{contact.email}</td>
                                <td>{contact.phoneNumber}</td>
                                <td>
                                    <button onClick={() => handleViewDetails(contact._id)} >View Details</button>
                                    <button onClick={() => handleDelete(contact._id)} className="delete-btn">Delete</button>

                                </td> {/* New actions */}

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </AdminLayout>
    );
};

export default ContactList;
