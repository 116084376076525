import React from 'react'
import ASSET_PATHS from '../../constant'
import './DashBoard.css';
import { useNavigate } from 'react-router-dom';
import AdminLayout from '../../component/Admin-Layout/AdminLayout';

export default function DashBoard() {
    const imgRoute = ASSET_PATHS.IMG_URL
    const iconRoute = ASSET_PATHS.ICON_URL
    const navigate = useNavigate();

    return (
        <>
            <AdminLayout>
                <div className="dashBoard">

                <h2 className="dashboard-title">Welcome to MustTech Solutions Admin Dashboard</h2>
                    <p className="dashboard-description">
                        Manage your job postings, applications, and candidates all in one place.
                        Stay updated with the latest statistics and insights to streamline your hiring process.
                    </p>
                    <div class="dashboard-cards">
                        <div class="card">
                            <div class="card-icon">
                                <i class="fas fa-briefcase"></i>
                            </div>
                            <div class="card-content">
                                <h3>Total Jobs</h3>
                                <p>50</p>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-icon">
                                <i class="fas fa-check-circle"></i>
                            </div>
                            <div class="card-content">
                                <h3>Active Jobs</h3>
                                <p>12</p>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-icon">
                                <i class="fas fa-users"></i>
                            </div>
                            <div class="card-content">
                                <h3>Applications</h3>
                                <p>120</p>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-icon">
                                <i class="fas fa-user-check"></i>
                            </div>
                            <div class="card-content">
                                <h3>Hired Candidates</h3>
                                <p>8</p>
                            </div>
                        </div>

                        
                    </div>



                </div>
            </AdminLayout>

        </>
    )
}
