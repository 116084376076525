import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token'); // Check if token exists

    if (!token) {
        // If there's no token, redirect to the login page
        return <Navigate to="/musttech-solution/admin-panel/login" replace />;
    }

    // If authenticated, render the child component
    return children;
};

export default ProtectedRoute;
