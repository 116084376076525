import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "./ContactDetail.css";
import AdminLayout from "../../component/Admin-Layout/AdminLayout";
import { API_URL } from "../../utils/API_URL";

const ContactDetail = () => {
    const { id } = useParams(); // Get the contact ID from the URL
    const [contact, setContact] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");


    useEffect(() => {
        const fetchContact = async () => {
            try {
                // Retrieve the token from localStorage (or wherever you stored it)
                const token = localStorage.getItem('token');

                // Configure the headers to include the Authorization token
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`, // Add Bearer token in Authorization header
                    },
                };
                const response = await axios.get(`${API_URL}/contact/get/${id}`, config);
                setContact(response.data);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch contact details");
                setLoading(false);
            }
        };

        fetchContact();
    }, [id]);


    return (
        <AdminLayout>

            <div className="contact-detail">
                <h2>Contact Details</h2>
                {contact && (
                    <div>
                        <p><strong>First Name:</strong> {contact.firstName}</p>
                        <p><strong>Last Name:</strong> {contact.lastName}</p>
                        <p><strong>Email:</strong> {contact.email}</p>
                        <p><strong>Phone:</strong> {contact.phoneNumber}</p>
                        <p><strong>Message:</strong> {contact.message}</p>
                        {/* {/* Add more fields if necessary  */}
                    </div>
                )}

                <Link to="/musttech-solution/admin-panel/contact" className="back-link">Back to Contact List</Link>
            </div>

        </AdminLayout>

    );
};

export default ContactDetail;
