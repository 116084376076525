import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Remove the token from localStorage or sessionStorage
        localStorage.removeItem('token');
        
        // Redirect to the login page
        navigate('/musttech-solution/admin-panel/login');
    }, [navigate]);

    return null; // No UI needed for the logout functionality
};

export default Logout;
