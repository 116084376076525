import React, { useEffect, useState } from "react";
// import JobForm from "./JobForm";
import "./ManageJob.css";
import AdminLayout from "../../component/Admin-Layout/AdminLayout";
import axios from "axios";
import { API_URL } from "../../utils/API_URL";
import { useNavigate } from "react-router-dom";

const ManageJob = () => {
    // Initial jobs data
    // Sample data for jobs
    const [jobs, setJobs] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await axios.get(`${API_URL}/job/get`);
                setJobs(response.data);
            } catch (err) {
                console.log(err);
            }
        };
        fetchContacts();
    }, []);

    const deleteJob = async (slug) => {
        try {
            // Retrieve the token from localStorage (or wherever you stored it)
            const token = localStorage.getItem('token');

            // Configure the headers to include the Authorization token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`, // Add Bearer token in Authorization header
                },
            };
            await axios.delete(`${API_URL}/job/delete/${slug}`,config);
            setJobs((prevJobs) => prevJobs.filter(job => job.slug !== slug));
        } catch (err) {
            console.log(err);
        }
    };

    const editJob = async (slug) => {
        navigate(`/musttech-solution/admin-panel/job/edit/${slug}`)
    };

    return (

        <AdminLayout>


            <div className="job-management">
                <div className="heading">
                    <h2>Manage Job Applications</h2>
                    <div>
                        <button onClick={() => navigate(`/musttech-solution/admin-panel/job/create`)}> Add Job </button>
                    </div>

                </div>


                <div className="job-list">
                    <h3>Job Applications</h3>
                    {jobs.length === 0 ? (
                        <p>No job applications available.</p>
                    ) : (
                        <ul>
                            {jobs.map((job) => (
                                <li key={job.id}>
                                    <div>
                                        <strong>{job.title}</strong>
                                        <p>{job.location}</p>
                                    </div>
                                    <div>
                                        <button onClick={() => editJob(job.slug)} className="edit">Edit</button>
                                        <button onClick={() => deleteJob(job.slug)}>Delete</button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </AdminLayout>

    );
};

export default ManageJob;
